import React, { Component, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Config, PackageNameUser } from "../../utils/config";
import { IsRolePackage } from "../../lib/helpers";

const SidebarComponent = (props) => {
  const { Platform } = props.Access;

  useEffect(() => {
    document
      .querySelectorAll('.nav-submenu[data-toggle="nav-submenu"]')
      .forEach((anchor) => {
        anchor.addEventListener("click", function (e) {
          e.preventDefault();
          e.stopPropagation();
          let list_class = e.target.parentNode.getAttribute("class");
          if (list_class.indexOf("open") > -1) {
            e.target.parentNode.classList.remove("open");
          } else {
            e.target.parentNode.classList.add("open");
          }
        });
      });
  }, [])

  useEffect(() => {
    console.log(Platform)
  }, [Platform])

  let pathname = props.location.pathname;
    return (
      <nav id="sidebar">
        <div className="sidebar-content">
          <div className="content-header content-header-fullrow px-15">
            <div className="content-header-section sidebar-mini-visible-b">
              <span className="content-header-item font-w700 font-size-xl float-left animated fadeIn">
                <span className="text-dual-primary-dark">c</span>
                <span className="text-primary">b</span>
              </span>
            </div>
            <div className="content-header-section text-center align-parent sidebar-mini-hidden">
              <button
                type="button"
                className="btn btn-circle btn-dual-secondary d-lg-none align-v-r"
                data-toggle="sidebar"
                data-action="sidebar_close"
              >
                <i className="fa fa-times text-danger"></i>
              </button>
              <div className="content-header-item">
                <a className="link-effect font-w700" href="/">
                  <i className="si si-fire text-primary"></i>
                  <span className="font-size-xl text-dual-primary-dark">T</span>
                  <span className="font-size-xl text-primary">ool</span>
                </a>
              </div>
            </div>
          </div>
          <div className="content-side content-side-full content-side-user px-10 align-parent">
            <div className="sidebar-mini-visible-b align-v animated fadeIn">
              <img
                className="img-avatar img-avatar32"
                src={require("../../media/avatars/avatar15.jpg")}
                alt=""
              />
            </div>
            <div className="sidebar-mini-hidden-b text-center">
              <a className="img-link">
                <img
                  className="img-avatar"
                  src={require("../../media/avatars/avatar15.jpg")}
                  alt=""
                />
              </a>
              <ul className="list-inline mt-10">
                <li className="list-inline-item">
                  <a className="link-effect text-dual-primary-dark font-size-sm font-w600 text-uppercase">
                    {Config.levelUser[props.userSystem.level]}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="link-effect text-dual-primary-dark"
                    data-toggle="layout"
                    data-action="sidebar_style_inverse_toggle"
                  >
                    <i className="si si-drop"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="link-effect text-dual-primary-dark"
                    onClick={() => props.logOut()}
                  >
                    <i className="si si-logout"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="content-side content-side-full">
            <ul className="nav-main">
              <li>
                <Link to="/" className={pathname == "/" ? "active" : ""}>
                  <i className="si si-user"></i>
                  <span className="sidebar-mini-hide">Quản lý nick</span>
                </Link>
              </li>

              {
                props.userSystem.level == 2 || props.userSystem.is_admin ?
                  <li>
                  <Link to="/share_data" className={pathname == "/share_data" ? "active" : ""}>
                    <i className="si si-share"></i>
                    <span className="sidebar-mini-hide">Quản lý chia sẻ TN</span>
                  </Link>
                </li>
                : null
              }
              

              <li>
                <Link
                  to="/manage-content"
                  className={pathname == "/manage-content" ? "active" : ""}
                >
                  <i class="far fa-lightbulb"></i>
                  <span className="sidebar-mini-hide">Quản lý content</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/actions"
                  className={
                    pathname == "/actions" ? "active" : ""
                  }
                >
                  <i class="far fa-toolbox"></i>
                  <span className="sidebar-mini-hide">
                      Quản lý hành động
                  </span>
                </Link>
              </li>

              <li class={pathname.indexOf("/create-action-") == 0 ? "open" : ""}>
                  <a class="nav-submenu" data-toggle="nav-submenu" href="#">
                    <i class="si si-star"></i>
                    <span class="sidebar-mini-hide">Quản lý chiến dịch</span>
                  </a>
                  <ul>
                    {
                      Platform.data.map((item) => {
                        let path = `/create-action-${item}`;
                        return <li>
                        <Link
                          to={path}
                          className={
                            pathname == path ? "active" : ""
                          }
                        >
                          {/* <i class="fab fa-facebook"></i> */}
                          Hành động {item}
                        </Link>
                      </li>
                      })
                    }
                  </ul>
              </li>
              
              <li class={pathname.indexOf("/app_run") == 0 ? "open" : ""}>
                  <a class="nav-submenu" data-toggle="nav-submenu" href="#">
                    <i class="si si-screen-desktop"></i>
                    <span class="sidebar-mini-hide">Quản lý thiết bị</span>
                  </a>
                  <ul>
                    <li>
                      <Link
                        to="/app_run/script"
                        className={pathname == "/app_run/script" ? "active" : ""}
                      >
                        Script
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/app_run"
                        className={pathname == "/app_run" ? "active" : ""}
                      >
                        Thiết bị
                      </Link>
                    </li>
                  </ul>
              </li>
              {
                IsRolePackage(PackageNameUser.copy_post.value, props.userSystem) ?
                <li>
                  <Link
                    to="/copy_post"
                    className={pathname == "/copy_post" ? "active" : ""}
                  >
                    <i className="fa fa-copy"></i>
                    <span className="sidebar-mini-hide">Đăng lại bài viết theo UID</span>
                  </Link>
                </li>
                : null
              }
              <li className={!(Platform.data.includes("tiktokTDS") || Platform.data.includes("tiktok-v2")) ? "d-none" : ""}>
                <Link
                  to="/jobs"
                  className={pathname == "/jobs" ? "active" : ""}
                >
                  <i className="si si-star"></i>
                  <span className="sidebar-mini-hide">Thống kê job TDS</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/googleapi"
                  className={pathname == "/googleapi" ? "active" : ""}
                >
                  <i className="si si-social-google"></i>
                  <span className="sidebar-mini-hide">Liên kết Google Driver</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/action/data"
                  className={pathname == "/action/data" ? "active" : ""}
                >
                  <i className="si si-layers"></i>
                  <span className="sidebar-mini-hide">Quản lý dữ liệu</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/proxy"
                  className={pathname == "/proxy" ? "active" : ""}
                >
                  <i className="si si-grid"></i>
                  <span className="sidebar-mini-hide">Proxy</span>
                </Link>
              </li>
              {
                props.userSystem.level == 5 || props.userSystem.is_admin && (props.setting && props.setting.link_ext) && (<li>
                  <Link
                    to="/log_get_cookie"
                    className={pathname == "/log_get_cookie" ? "active" : ""}
                  >
                    <i className="si si-docs"></i>
                    <span className="sidebar-mini-hide">Log get cookie</span>
                  </Link>
                </li>)
              }
              {/* <li>
                <Link to="/sms" className={pathname == "/sms" ? "active" : ""}>
                  <i className="si si-screen-smartphone"></i>
                  <span className="sidebar-mini-hide">Manager Otp sms</span>
                </Link>
              </li> */}
              {
                props.userSystem.level == 5 || props.userSystem.is_admin && (props.setting && props.setting.link_ext) && (<li>
                  <a
                    href={props.setting ? props.setting.link_ext : ""}
                    target="_blank"
                  >
                    <i className="si si-cloud-download"></i>
                    <span className="sidebar-mini-hide">Extension</span>
                  </a>
                </li>)
              }
              {props.userSystem.level == 2 || props.userSystem.is_admin ? (
                <li>
                  <Link
                    to="/email"
                    className={pathname == "/email" ? "active" : ""}
                  >
                    <i className="si si-envelope-letter"></i>
                    <span className="sidebar-mini-hide">Manager email</span>
                  </Link>
                </li>
              ) : null}

              {
                props.userSystem.level == 6 && (<li>
                  <Link
                    to="/ref/user"
                    className={pathname == "/ref/user" ? "active" : ""}
                  >
                    <i className="si si-users"></i>
                    <span className="sidebar-mini-hide">Quản lý ctv</span>
                  </Link>
                </li>)
              }

              {props.userSystem.is_admin ? (
                <li class={pathname.indexOf("/admin") > -1 ? "open" : ""}>
                  <a class="nav-submenu" data-toggle="nav-submenu" href="#">
                    <i class="si si-support"></i>
                    <span class="sidebar-mini-hide">Quản trị</span>
                  </a>
                  <ul>
                    <li>
                      <Link to="/admin/user" className={pathname == '/admin/user' ? 'active' : ''}>
                        Tài khoản
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/noti_system"
                        className={
                          pathname == "/admin/noti_system" ? "active" : ""
                        }
                      >
                        Thông báo hệ thống
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/app_run"
                        className={
                          pathname == "/admin/app_run" ? "active" : ""
                        }
                      >
                        Quản lý thiết bị
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/script"
                        className={
                          pathname == "/admin/script" ? "active" : ""
                        }
                      >
                        Quản lý script
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/action_platform"
                        className={
                          pathname == "/admin/action_platform" ? "active" : ""
                        }
                      >
                        Quản lý form hành động
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/system_data"
                        className={
                          pathname == "/admin/system_data" ? "active" : ""
                        }
                      >
                        Quản lý tài nguyên
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              <li>
                <a
                  className="{{ request()->is('logout*') ? ' active' : '' }}"
                  onClick={() => props.logOut()}
                >
                  <i className="si si-logout"></i>
                  <span className="sidebar-mini-hide">Đăng xuất</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
}

const mapStateToProps = (state) => ({
  Access: state.Access
})

export default connect(mapStateToProps)(withRouter(SidebarComponent))

// export default class SidebarComponent extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }
//   componentDidMount = () => {
//     document
//       .querySelectorAll('.nav-submenu[data-toggle="nav-submenu"]')
//       .forEach((anchor) => {
//         anchor.addEventListener("click", function (e) {
//           e.preventDefault();
//           e.stopPropagation();
//           let list_class = e.target.parentNode.getAttribute("class");
//           if (list_class.indexOf("open") > -1) {
//             e.target.parentNode.classList.remove("open");
//           } else {
//             e.target.parentNode.classList.add("open");
//           }
//         });
//       });
//   };
//   render() {
    
//   }
// }
